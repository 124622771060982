<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true"
             :close-on-press-escape="closeOnPressEscape"
             :width="width"
             :close-on-click-modal="closeOnClickModal" @opened="opened" @close="close"
             :custom-class="customClass"
             :top="top">
    <slot></slot>
    <div slot="footer" style="text-align: right" v-if="!basic">
      <slot name="footer">
        <template>
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="_confirm" :loading="loading">确 定</el-button>
        </template>
      </slot>
    </div>
  </el-dialog>
</template>
<script>
export default {

  props: {

    title: {
      type: String,
      default: ''
    },

    closeOnPressEscape: {
      type: Boolean,
      default: true
    },

    closeOnClickModal: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: '50%'
    },

    basic: {
      type: Boolean,
      default: false
    },

    customClass: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: '10vh'
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialogVisible: false,
    }
  },

  methods: {

    show() {
      this.dialogVisible = true
    },

    close() {
      this.$emit('close')
      this.dialogVisible = false
    },


    _cancel() {
      this.$emit('cancel')
    },

    _confirm() {
      this.$emit('confirm')
    },

    opened() {
      this.$emit('opened')
    }
  }
}

</script>
<style lang="scss">
.el-dialog.transparent {
  overflow: hidden;
  position: relative;
  background-image: url(/static/images/dialog.png);
  background-size: cover; background-position: center 0;
  .el-form-item--small .el-form-item__label{
    color: #FFF;
  }
  .el-radio__label{
    color: #FFF;
  }
}
</style>

