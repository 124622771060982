<template>
  <container @onBeforeCloseWindow="onBeforeCloseWindow">
    <div
      style="height: 100%;overflow: auto;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;    justify-content: center;"
      class="clearfix">

      <div style="flex-shrink: 0;position: relative;min-width: 3.48rem;height: 3.52rem;float: left;margin-right: 30px"
           v-for="(item, index) in videoList" :key="item.id" :style="index === 0 ? bigPlayerSize: ''">
        <player-container :display="item.display" :name="item.name">
          <video :id="item.id" ref="video" playsinline width="100%" height="100%" autoplay
                 v-show="item.display" @click="play(item)"></video>
        </player-container>
      </div>
    </div>

    <div slot="right" style="height: 100%;display: flex;flex-direction: column;">
      <item-header title="成员列表"/>
      <member-list :members="memberList" @audio="changeMemberAudio" @video="changeMemberVideo"/>
      <item-header title="在线沟通"/>
      <text-chat-content-list :chat-list="textRoomChatList"/>
      <text-chat-send @send="sendTextMessage"/>
      <item-header title="文件上传">
        <div
          style="display: flex;flex-direction: row;width: 56px;height: 24px;font-size: 14px;background:#6EB2FF;border-radius:12px;color: #FFF;line-height: 24px;text-align: center;justify-content: center">
          <span>上传</span>
        </div>
      </item-header>
      <files/>
    </div>

    <div slot="bottom">
      <div style="display: flex;flex-direction: row">
        <operation @changeMediaSource="shareScreen" @toggleVideoMuted="toggleVideoMuted"
                   @toggleMicrophoneMuted="toggleMicrophoneMuted"/>
        <div style="flex: 1"></div>
        <sound-volume @changeBitrate="changeBitrate"/>
      </div>
    </div>


<!--    <dialog ref="form" basic custom-class="transparent">-->
<!--      <el-form class="transparent">-->
<!--        <el-form-item label="角色">-->
<!--          <el-radio-group v-model="form.rule">-->
<!--            <el-radio label="host">我是主播</el-radio>-->
<!--            <el-radio label="audience">我是观众</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="参与人数">-->
<!--          <el-input v-model="form.password"></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <el-button @click="$refs.form.close()">取消</el-button>-->
<!--      <el-button type="primary" @click="createRoom">确定</el-button>-->
<!--    </dialog>-->
  </container>
</template>
<script>
import Container from './components/Container'
import Conferencing from '../../util/conferencing'
import ItemHeader from './components/ItemHeader'
import MemberList from './components/MemberList'
import TextChatContentList from './components/TextChatContentList'
import TextChatSend from './components/TextChatSend'
import Operation from './components/Operation'
import Files from './components/Files'
import SoundVolume from './components/SoundVolume'
import PlayerContainer from './components/PlayerContainer'
import { Loading } from 'element-ui'
import common from './mixins/common'
import textRoom from './mixins/textRoom'
import videoRoom from './mixins/videoRoom'
import dialog from './components/Dialog'
export default {

  name: 'video-conferencing-single',

  mixins: [common, textRoom, videoRoom],

  components: {
    Container,
    ItemHeader,
    MemberList,
    TextChatContentList,
    TextChatSend,
    Operation,
    Files,
    SoundVolume,
    PlayerContainer,
    dialog
  },

  data () {
    return {
      conferencing: null,
      roomId: Number(this.$route.query.room),
      feeds: [],
      videoList: [],
      memberList: [],
      textRoomUserMapper: {},
      textRoomChatList: [],
      transaction: (new Date()).getTime().toString(), // this.$tools.uuid(),
      videoRoomSessionId: (new Date()).getTime().toString(),
      mediaSource: 'video',
      bigPlayerSize: 'width: 7.22rem;height:7.3rem',
      isJoined: false,
      isOwner: false,
      form: {
        rule: 'audience',
        password: ''
      }
    }
  },

  created () {
    for (let i = 0; i < 2; i++) {
      this.videoList.push({
        title: '',
        id: this.$tools.uuid(),
        display: false,
        feed: null
      })
    }

    const _this = this
    this.conferencing = new Conferencing(this.gateway, {
      success: function () {
        _this.useTextRoom()
        _this.useVideoRoom()
      }
    })
  },

  methods: {

    // 使用视频插件
    useVideoRoom () {
      const _this = this
      this.conferencing.attachVideoRoom({

        onSuccess: function (handler) {
          handler.send({
            message: { request: 'list', room: _this.roomId },
            success: function (res) {
              const item = res.list.find(item => item.room === _this.roomId)
              if (item === undefined) return
              const info = JSON.parse(item.description)
              console.log(_this.userInfo)
              if (info.user.mobile !== _this.userInfo.phoneNumber) return
              _this.isOwner = true
              _this.joinVideoRoom(handler)
            }
          })

          handler.send({
            message: { request: 'listparticipants', room: _this.roomId },
            success: function (res) {
              console.log('res.participants', res.participants, _this.videoRoomSessionId)
              res.participants.forEach(item => _this.createRemoteFeed(item.id, item.display))
              _this.relationUser()
            }
          })
        },

        onJoined: function (handler, message) {
          _this.isJoined = true
          _this.videoRoomSessionId = message.id
          _this.shareScreen('camera')
          console.log('message.publishers', message.publishers, _this.videoRoomSessionId)
          message.publishers.forEach(item => {
            _this.createRemoteFeed(item.id, item.display, item.audio_codec, item.video_codec)
          })
        },

        onLeaving: function (handler, id) {
          console.log('onLeaving')
          const remoteFeed = _this.videoList.find(item => item.feed && item.feed.rfid === id)
          if (remoteFeed === undefined) return
          const videoItem = _this.videoList[remoteFeed.feed.rfindex]
          videoItem.display = false

          videoItem.video = false
          videoItem.audio = false
          remoteFeed.feed.send({
            message: {
              request: 'leave'
            }
          })
          remoteFeed.feed.detach()
          videoItem.feed = null
        },

        onClearUp: function (handler, id) {
          // console.log(JSON.stringify(_this.videoList), id, 'ididididididididididididididididid')
          const remoteFeed = _this.videoList.find(item => item.feed && item.feed.rfid === id)
          if (remoteFeed === undefined) return
          const videoItem = _this.videoList[remoteFeed.feed.rfindex]
          videoItem.display = false
          videoItem.video = false
          videoItem.audio = false
          remoteFeed.feed.detach()
          videoItem.feed = null
          _this.relationUser()
        },

        onAttached: function (item) {
          _this.createRemoteFeed(item.id, item.display)
        },

        onLocalStream: function (stream) {
          _this.videoList[0].display = true
          _this.videoList[0].name = _this.nickName
          _this.videoList[0].feed = { rfid: _this.videoRoomSessionId, rfindex: 0, detach: function () {} }
          window.Janus.attachMediaStream(_this.$refs.video[0], stream)
        }
      })
    },

    subscribeVideo (sessionId) {
      this.createRemoteFeed(sessionId, this.nickName)
    },

    relationUser () {
      console.log(this.videoList, '========================')
      console.log(this.memberList, '========================')
      this.videoList.forEach(item => {
        if (item.feed) {
          const member = this.memberList.find(member => Number(member.id) === item.feed.rfid)
          console.log(member)
          member.video = item.video
        }
      })
    },

    // 共享屏幕
    shareScreen (mediaSource) {
      const _this = this
      if (!mediaSource) mediaSource = 'video'
      this.mediaSource = mediaSource
      const media = { audioRecv: false, videoRecv: false, audioSend: true, videoSend: true }
      if (mediaSource === 'screen') media.video = mediaSource
      this.stopPublishMedia(function () {
        setTimeout(() => {
          _this.conferencing.handler.videoRoom.createOffer({
            media,
            simulcast: false,
            simulcast2: false,
            success: function (jsep) {
              var publish = { request: 'configure', audio: true, video: true }
              // publish.audiocodec = 'acodec'
              // publish.videocodec = 'vcodec'
              _this.conferencing.handler.videoRoom.send({ message: publish, jsep: jsep })
              _this.sendMessageToAll({
                type: 'joined',
                id: _this.videoRoomSessionId
              })
            },
            error: function (error) {
              window.Janus.error('WebRTC error:', error)
            }
          })
        }, 1000)
      })
    },

    play (item) {
      const el = document.getElementById(item.id)
      el.play()
    }
  }
}
</script>
<style scoped>
.monitoring-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #000
}
</style>
